import { useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha';


const ConnectForm = ({setConnectNow, id, formData, conversationId, randomId, setShowForm, setShowFormLast}) => {
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(false);
    const onChange = (value) => {
      if (value) {
        console.log("Captcha value:", value);
        setVerified(true);
      }
    };
    const handleFeedbackForm = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const topLevelFields = {};
        const labelValues = {};
        formData.forEach((value, key) => {
            if (['email', 'name', 'mobileNumber'].includes(key)) {
                topLevelFields[key] = value.toString();
            } else if (key !== 'g-recaptcha-response') {
                labelValues[key] = value.toString();
            }
        });
        const raw = JSON.stringify({
            "project_id": id,
            "conversation_id": conversationId,
            "customer_user_id": randomId,
            ...topLevelFields,
            "label_values": {
                ...labelValues
            }
        });
        setLoading(true)
        if (id && verified) {
            const requestOptions = {
              method: 'POST',
              body: raw,
              redirect: 'follow'
            };
            try {
              const response = await fetch(`https://2gsodzdvta.execute-api.ap-south-1.amazonaws.com/production/crawler/saveLeadForm`, requestOptions);
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const result = await response.json();
              console.log(result, 'response');
              setConnectNow(3)
              setTimeout(() => {
                setShowForm(false)
                setConnectNow(1)
                setShowFormLast(true)
                }, 8000);
            } catch (error) {
              console.error('Error fetching lead label:', error);
              setConnectNow(1)
            } finally {
                setLoading(false);
            }
        }
    }
    return (
        formData?.data ?
        <form onSubmit={(e) => {handleFeedbackForm(e)}} id="chatBot" className='lg:w-[652px] w-full rounded-lg border border-black/10 bg-[#F9FAFB] overflow-hidden'>
            <div className="h-[54px] px-4 pt-4 pb-3 bg-[#f3eefb] border-b border-slate-200 justify-between items-center flex">
                <h3 className="text-black text-base font-semibold leading-relaxed">Connect with Agent</h3>
                <button onClick={(e)=>{e.preventDefault(); setConnectNow(1)}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M5 15L15 5M5 5L15 15" stroke="#475569" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className='p-4'>
                <div className='flex flex-wrap gap-4 mb-6'>
                    {formData.data?.name && formData.data?.name[0] &&
                        <div className='w-full md:w-[calc(50%-8px)]'>
                            <label htmlFor="name" className='text-gray-700 text-xs font-semibold leading-tight block mb-1.5 w-full'>{formData.data.name[0].fieldLabel}</label>
                            <input required={formData.data.name[0].required} type="text" id="name" name="name" placeholder={formData.data.name[0].placeholder} className='h-10 px-4 py-2.5 bg-white rounded-md border border-gray-300 w-full text-gray-800 text-sm font-medium leading-tight' />
                        </div>
                    } 
                    {formData.data.email && formData.data.email[0] &&
                        <div className='w-full md:w-[calc(50%-8px)]'>
                            <label htmlFor="email" className='text-gray-700 text-xs font-semibold leading-tight block mb-1.5 w-full'>{formData.data.email[0].fieldLabel}</label>
                            <input required={formData.data.email[0].required} type="email" id="email" name="email" placeholder={formData.data.email[0].placeholder} className='h-10 px-4 py-2.5 bg-white rounded-md border border-gray-300 w-full text-gray-800 text-sm font-medium leading-tight' />
                        </div>
                    }
                    {formData?.data?.mobileNumber && formData.data.mobileNumber[0] &&
                        <div className='w-full md:w-[calc(50%-8px)]'>
                            <label htmlFor="mobileNumber" className='text-gray-700 text-xs font-semibold leading-tight block mb-1.5 w-full'>{formData.data.mobileNumber[0].fieldLabel}</label>
                            <input required={formData.data.mobileNumber[0].required} type="tel" id="mobileNumber" name="mobileNumber" placeholder={formData.data.mobileNumber[0].placeholder} className='h-10 px-4 py-2.5 bg-white rounded-md border border-gray-300 w-full text-gray-800 text-sm font-medium leading-tight' />
                        </div>
                    }
                    {formData.data?.smallText && formData.data?.smallText[0] &&
                        <div className='w-full md:w-[calc(50%-8px)]'>
                            <label htmlFor={formData.data.smallText[0].fieldLabel} className='text-gray-700 text-xs font-semibold leading-tight block mb-1.5 w-full'>{formData.data.smallText[0].fieldLabel}</label>
                            <input required={formData.data.smallText[0].required} type="text" id={formData.data.smallText[0].fieldLabel} name={formData.data.smallText[0].fieldLabel} placeholder={formData.data.smallText[0].placeholder} className='h-10 px-4 py-2.5 bg-white rounded-md border border-gray-300 w-full text-gray-800 text-sm font-medium leading-tight' />
                        </div>
                    }
                    {formData.data?.multipleSelect && formData.data?.multipleSelect[0] && 
                        <div className='w-full md:w-[calc(50%-8px)]'>
                            <label htmlFor={formData.data.multipleSelect[0].fieldLabel} className='text-gray-700 text-xs font-semibold leading-tight block mb-1.5 w-full'>{formData.data.multipleSelect[0].fieldLabel}</label>
                            <select required={formData.data.multipleSelect[0].required} id={formData.data.multipleSelect[0].fieldLabel} placeholder={formData.data.multipleSelect[0].placeholder} name={formData.data.multipleSelect[0].fieldLabel} className='h-10 px-2.5 py-1 bg-white rounded-md border border-gray-300 w-full text-gray-800 text-sm font-medium leading-tight'>
                                {formData.data.multipleSelect[0].options.map((option, index)=>(
                                    <option key={index} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>
                    }
                    {formData.data?.singleSelect && formData.data?.singleSelect[0] && 
                        <div className='w-full md:w-[calc(50%-8px)]'>
                            <p className='text-gray-700 text-xs font-semibold leading-tight block mb-1.5 w-full'>{formData.data.singleSelect[0].fieldLabel}</p>
                            <div className='flex gap-3 items-center'>
                                {formData.data.singleSelect[0].options.map((option, index)=>(
                                    <div className='flex gap-1 items-center cursor-pointer' key={index}>
                                        <input required={formData.data.singleSelect[0].required} className='cursor-pointer' defaultChecked type="radio" id={`${formData.data.singleSelect[0].fieldLabel}${index}`} name={formData.data.singleSelect[0].fieldLabel} value={option} />
                                        <label className='text-gray-800 cursor-pointer text-sm font-medium leading-tight' htmlFor={`${formData.data.singleSelect[0].fieldLabel}${index}`}>{option}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    {formData.data?.largeText && formData.data?.largeText[0] &&
                        <div className='w-full md:w-[calc(50%-8px)]'>
                            <label htmlFor={formData.data.largeText[0].fieldLabel} className='text-gray-700 text-xs font-semibold leading-tight block mb-1.5 w-full'>{formData.data.largeText[0].fieldLabel}</label>
                            <textarea required={formData.data.largeText[0].required} id={formData.data.largeText[0].fieldLabel} name={formData.data.largeText[0].fieldLabel} placeholder={formData.data.largeText[0].placeholder} className='px-4 py-2.5 bg-white rounded-md border border-gray-300 w-full text-gray-800 text-sm font-medium leading-tight' />
                        </div>
                    }
                </div>
                <div className="mb-4">
                    <ReCAPTCHA
                        sitekey="6LfCdHAqAAAAANprSZ_uMd_dDfuW-CVwKCmc32E_"
                        onChange={onChange}
                    />
                </div>
                <button disabled={loading && !verified} type='submit' className="h-[38px] px-4 py-[9px] bg-[#8451d4] rounded justify-start items-center gap-2 inline-flex text-white text-base font-semibold leading-tight">
                    {loading ? 'Submitting' : 'Submit'}
                </button>
            </div>
        </form> : <p>Error fetching form data</p>
    );
}

export default ConnectForm;