import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as BrandIcon } from '../images/brand-icon.svg';
import { ReactComponent as Tick } from '../images/tick.svg';
import { ReactComponent as User } from '../images/user.svg';
import ConnectForm from './ConnectForm';
import { Loader } from "lucide-react"
import Cookies from 'js-cookie';

const useQuery = () => {
    const location = useLocation();
    return new URLSearchParams(location.search);
};

const Conversation = ({formData, setShowFormLast, showForm, id, promptLoading, setShowForm, aiAnswer, showAvatar, conversationId, randomId, errorResponse, prompt, image, setMessages, messages}) => {
    const query = useQuery();
    const scrollConversations = query.get('conversations');
    const [connectNow, setConnectNow] = useState(1);
    const messagesEndRef = useRef(null);
    const [formatMessage, setFormateMessage] = useState(messages); 
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
        const savedMessages = Cookies.get('conversationMessages');
        if (savedMessages) {
            setMessages(JSON.parse(savedMessages));
        }
    }, []);
    useEffect(() => {
        Cookies.set('conversationMessages', JSON.stringify(messages), { expires: 0.5, sameSite: 'None', secure: true });
    }, [messages]);
    useEffect(() => {
        const filteredMessages = messages.filter(msg => !(msg.type === 'ai' && msg.content === 'loading' && !promptLoading));
        setFormateMessage(filteredMessages);
    }, [messages]);
    useEffect(() => {
        if (prompt) {
            setMessages(prevMessages => [
                ...prevMessages,
                { type: 'user', content: prompt }
            ]);
        }
    }, [prompt]);
    useEffect(() => {
        if (promptLoading) {
            setMessages(prevMessages => [
                ...prevMessages,
                { type: 'ai', content: 'loading' }
            ]);
        }
    }, [promptLoading]);
    useEffect(() => {
        if (errorResponse) {
            setMessages(prevMessages => 
                prevMessages.map((msg, index) => 
                    index === prevMessages.length - 1 && msg.type === 'ai' && msg.content === 'loading'
                        ? { ...msg, content: 'error' }
                        : msg
                )
            );
        }
    }, [errorResponse]);
    useEffect(() => {
        if (!scrollConversations || scrollConversations !== 'true') {
            scrollToBottom();
        }
    }, [messages, promptLoading, errorResponse, formatMessage]);
    const customerId = Cookies.get('customerId');
      if(randomId && !customerId) {
        Cookies.set('customerId', JSON.stringify(randomId), { expires: 0.5, sameSite: 'None', secure: true });
    }
    return (
        <div className='w-[786px] max-w-full p-5 px-5 max-h-[calc(100vh-120px)] overflow-y-scroll visible-area flex flex-col gap-2' >
            {formatMessage.map((message, index) => (
                <div key={index} className='flex'>
                    {message.type === 'user' ? (
                        <div className="max-w-full justify-end items-start gap-2 md:gap-2.5 inline-flex ml-auto mt-3 md:mt-[20px] mb-2 md:mb-[18px]">
                            <div className="flex-col justify-start items-end gap-2 inline-flex">
                                <div className="md:px-4 md:py-4 px-3 py-[10px] bg-[#f6f6f7] rounded-md md:rounded-lg rounded-tr-[0] border border-black/10 justify-end items-center gap-2.5 inline-flex">
                                    <p className="text-slate-800 text-sm md:text-base font-normal md:leading-7">{message.content}</p>
                                </div>
                            </div>
                            <div className="w-8 h-8 md:w-10 md:h-10 relative">
                                <User className='w-8 h-8 md:w-10 md:h-10' />
                            </div>
                        </div>
                    ) : (
                        <div className='flex gap-4 md:gap-5 md:px-5'>
                            <div className="min-h-[28px] h-[28px] min-w-fit w-fit md:h-[34px] md:min-w-[34px] md:w-[34px] rounded-[60px] justify-start items-center gap-2.5 inline-flex">
                                {
                                    showAvatar && image ?
                                    <img className='w-auto h-auto max-w-full max-h-full object-cover' src={image} alt="logo" /> :
                                    <BrandIcon />
                                }
                            </div>
                            <div className='flex flex-col gap-5'>
                                {message.content === 'loading' ? promptLoading && index === messages.length - 1 ? (
                                    <div className='flex items-center gap-2 text-sm md:text-base mt-[3px] md:mt-1'>
                                        Searching for information..
                                        <Loader className="h-4 w-4 animate-spin" />
                                    </div> 
                                ) : <p className='text-red-700 pt-1'>Process Interrupted, please try again!</p> : message.content === 'error' ? (
                                    <p className='text-red-700 pt-1'>{errorResponse ? errorResponse : 'There was an error loading response'}!</p>
                                ) : (
                                    <div className='agent pr-10 lg:pr-12' dangerouslySetInnerHTML={{ __html: message.content }} />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ))}
            {showForm &&
                <div className='lg:p-4 lg:px-12 lg:pl-[72px]'>
                    {connectNow === 3 ?
                        <div className="h-12 px-4 py-3.5 bg-[#8451d4]/10 rounded-lg border border-black/10 justify-start items-center gap-2.5 inline-flex w-full">
                            <div className="justify-start items-center gap-3 flex w-full">
                                <Tick />
                                <p className="text-slate-800 text-sm font-normal leading-tight">Thank you for submitting your feedback</p>
                                <button className='ml-auto' onClick={()=>{setShowForm(false)}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M4 12L12 4M4 4L12 12" stroke="#1E293B" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        </div> : connectNow === 2 ?
                        <ConnectForm setShowFormLast={setShowFormLast} randomId={customerId ? JSON.parse(customerId) : randomId} conversationId={conversationId} formData={formData} id={id} setConnectNow={setConnectNow} setShowForm={setShowForm} /> : connectNow === 1 ?
                        <div className="bg-[#6a47f2]/10 rounded-lg border border-black/10 justify-between items-center flex p-4 py-2 md:py-4 gap-4">
                            <p className="text-slate-900 text-xs sm:text-base font-normal leading-relaxed">Need more help? Connect with a human agent.</p>
                            <button className="md:h-9 h-8 px-3 md:px-4 py-2 bg-[#6a47f2] hover:bg-[#5a3dcf] rounded text-white text-[12px] md:text-sm font-semibold flex items-center whitespace-nowrap leading-[3]" onClick={()=>{setConnectNow(2)}}>
                                Connect Now
                            </button>
                        </div> : ''
                    }
                </div> 
            }
            <div ref={messagesEndRef} />
        </div>
    );
};

export default Conversation;