import './App.css';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader2 } from "lucide-react"
import { ReactComponent as Logo } from './images/logo.svg';
import { ReactComponent as BigLogo } from './images/tta-logo.svg';
import LocationDetector from './components/LocattionDetactor';
import Conversation from './components/Conversation';
import Cookies from 'js-cookie';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function WidgetPage() {
    const questions = [
        'Learn more about the solutions we provide?',
        'Learn more about the solutions we provide?',
        'Learn more about the solutions we provide?'
    ];
    const [preview, setPreview] = useState(true);
    const [randomId, setRandomId] = useState('')
    const query = useQuery();
    const id = query.get('id');
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [data, setData] = useState(null);
    const [formData, setFormData] = useState(null);
    const [promptLoading, setPromptLoading] = useState(false)
    const [prompt, setPrompt] = useState(false)
    const [conversationId, setConversationId] = useState('')
    const [aiAnswer, setAiAnswer] = useState('')
    const [errorResponse, setErrorResponse] = useState(null)
    const [showForm, setShowForm] = useState(true)
    const [showFormLast, setShowFormLast] = useState(false)
    function generateRandomId(length = 8) {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        result += chars[Math.floor(Math.random() * 52)];
        for (let i = 1; i < length; i++) {
          result += chars[Math.floor(Math.random() * chars.length)];
        }
        return result;
    }
    useEffect(() => {
        const fetchData = async () => {
          if (id) {
            const requestOptions = {
              method: 'GET',
              redirect: 'follow'
            };
            try {
              const response = await fetch(`https://2gsodzdvta.execute-api.ap-south-1.amazonaws.com/production/crawler/getDesignSettingsForChatbot?project_id=${id}`, requestOptions);
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const result = await response.json();
              setData(result);
            } catch (error) {
              setError(error.message);
            } finally {
              setLoading(false);
            }
          }
        };
        fetchData();
        const fetchData2 = async () => {
            if (id) {
              const requestOptions = {
                method: 'GET',
                redirect: 'follow'
              };
              try {
                const response = await fetch(`https://2gsodzdvta.execute-api.ap-south-1.amazonaws.com/production/crawler/getLeadLabel?project_id=${id}`, requestOptions);
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setFormData(result);
              } catch (error) {
                console.error('Error fetching lead label:', error);
              }
            }
        };
        fetchData2();
        setRandomId(generateRandomId())
    }, [id]);
    function getDeviceType() {
        const width = window.innerWidth;
        if (width <= 767) return 'Mobile';
        if (width <= 1024) return 'Tablet';
        return 'Desktop';
    }
    function getBrowserType() {
        const userAgent = navigator.userAgent;
        let browserName;
        if (userAgent.match(/chrome|chromium|crios/i)) {
          browserName = "Chrome";
        } else if (userAgent.match(/firefox|fxios/i)) {
          browserName = "Firefox";
        } else if (userAgent.match(/safari/i)) {
          browserName = "Safari";
        } else if (userAgent.match(/opr\//i)) {
          browserName = "Opera";
        } else if (userAgent.match(/edg/i)) {
          browserName = "Edge";
        } else if (userAgent.match(/msie|trident/i)) {
          browserName = "Internet Explorer";
        } else {
          browserName = "Unknown";
        }
        return browserName;
    }
    function getOS() {
        const userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];
      
        let os = null;
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (/Linux/.test(platform)) {
          os = 'Linux';
        }
        return os;
    }
    const screenSize = getDeviceType();
    const browserType = getBrowserType();
    const operatingSystem = getOS();
    const country = LocationDetector();
    const handleMessage = async (text) => {
        setAiAnswer('')
        setPromptLoading(true);
        setPrompt(text)
        setInputValue('')
        setPreview(false)
        setShowForm(false)
        const customerId = Cookies.get('customerId');
        const raw = JSON.stringify({
            "website_id": id,
            "customer_user_id": customerId ? JSON.parse(customerId) : randomId,
            "prompt": text,
            "browser_type": browserType,
            "country": country,
            "device_type": screenSize,
            "domain": window?.location?.hostname,
            "landing_page": window?.location?.pathname,
            "os_type": operatingSystem,
            "reference": null
        });
        const requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };
        try {
            const response = await fetch(`https://2gsodzdvta.execute-api.ap-south-1.amazonaws.com/production/crawler/QueryLLM`, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setConversationId(result.conversation_id)
            setAiAnswer(result.response)
            if (showFormLast) {
              setShowForm(result.show_lead_form)
            } else {
              setShowForm(true)
            }
            if (result.response) {
              setMessages(prevMessages => 
                  prevMessages.map((msg, index) => 
                      index === prevMessages.length - 1 && msg.type === 'ai' && msg.content === 'loading'
                          ? { ...msg, content: result.response }
                          : msg
                  )
              );
          }
        } catch (error) {
            console.error('Error fetching lead label:', error);
            setErrorResponse('There was an error getting response')
            if (!showFormLast) {
              setShowForm(true)
            }
        } finally {
            setPromptLoading(false);
            setPrompt('')
        }
    }
    const [inputValue, setInputValue] = useState('');
    const handlePrompt = (e) => {
        e.preventDefault();
        if (e.target.prompt.value.length !== 0) {
            handleMessage(e.target.prompt.value);
        }
    }
    const [messages, setMessages] = useState([]);
    useEffect(() => {
        const savedMessages = Cookies.get('conversationMessages');
        if (savedMessages) {
          setMessages(JSON.parse(savedMessages));
          if(JSON.parse(savedMessages).length > 0) {
            setPreview(false)
          }
        }
    }, []);
    useEffect(() => {
      const customerId = Cookies.get('customerId');
      if(randomId && !customerId) {
        Cookies.set('customerId', JSON.stringify(randomId), { expires: 0.5 });
      }
    }, [messages]);

    return ( error ? 
        <div className='flex flex-col min-h-screen items-center justify-center relative bg-white'>
            <h2 className='text-3xl font-semibold mb-2'>There was an error with this chat bot please try later !!</h2>
            <p className='text-red-700 font-medium'>{error}</p>
        </div> : loading || !data ? <div className='min-h-screen flex items-center justify-center relative bg-white'><Loader2 className="h-4 w-4 animate-spin" /></div> :
        <div className="flex items-center main-screen h-screen flex-col justify-center relative bg-white">
            {preview ? data?.brand_logo_url ? <img className='absolute logo top-6 left-6 md:top-8 md:left-8 max-w-6 object-cover' src={data.brand_logo_url} alt="logo" /> :
                <Logo className='absolute top-6 left-6 md:top-8 md:left-8 logo' /> : data.brand_logo_url ? <img src={data.brand_logo_url} alt="logo" className='absolute top-6 logo left-6 md:top-8 md:left-8 hidden lg:block max-w-6 object-cover' /> :
                <Logo className='absolute top-6 left-6 md:top-8 md:left-8 hidden lg:block logo' />
            }
            {preview ?
                <div className='mt-5 md:mt-0 w-[786px] max-w-full p-5'>
                    <h1 className='text-[#6a47f2] text-3xl lg:leading-[1.3] leading-[1.3] lg:text-[46px] font-semibold lg:font-bold mb-10' style={{color : data?.AI_brand_color ? data?.AI_brand_color : ''}}>
                        Welcome!
                        <span className='block text-gray-300'>{data?.AI_initial_message ? data.AI_initial_message : 'How can I help you today?'}</span>
                    </h1>
                    <div className="justify-start items-center gap-5 inline-flex w-full max-w-full overflow-x-scroll visible-area">
                        {(data?.AI_suggested_prompt ? data?.AI_suggested_prompt : questions).map((question, index)=>(
                            <button onClick={() => handleMessage(question)} className="min-h-[214px] min-w-[235.34px] pr-[0.67px] cursor-pointer pb-px justify-center items-center flex focus-visible:outline-[#8451D4] focus:outline-[#8451D4]" key={index}>
                                <div className="grow shrink basis-0 self-stretch px-5 py-4 bg-[#f6f6f7] rounded-lg border border-[#dfdfe2] flex-col justify-start items-start gap-4 inline-flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                        <path d="M23.0123 16.3366C15.9763 14.9857 15.0152 14.0246 13.6651 6.98866C13.6443 6.88028 13.5863 6.78254 13.5012 6.71225C13.4161 6.64197 13.3092 6.60352 13.1989 6.60352C13.0885 6.60352 12.9816 6.64197 12.8965 6.71225C12.8114 6.78254 12.7534 6.88028 12.7326 6.98866C11.3825 14.0246 10.4214 14.9857 3.38508 16.3366C3.27672 16.3573 3.17899 16.4152 3.10869 16.5002C3.0384 16.5853 2.99994 16.6921 2.99994 16.8024C2.99994 16.9128 3.0384 17.0196 3.10869 17.1047C3.17899 17.1897 3.27672 17.2476 3.38508 17.2683C10.4214 18.6188 11.3825 19.5791 12.7326 26.6158C12.7535 26.7241 12.8115 26.8218 12.8966 26.892C12.9817 26.9622 13.0886 27.0006 13.1989 27.0006C13.3092 27.0006 13.416 26.9622 13.5011 26.892C13.5862 26.8218 13.6442 26.7241 13.6651 26.6158C15.0152 19.5791 15.9763 18.6188 23.0123 17.2683C23.1206 17.2476 23.2183 17.1897 23.2886 17.1047C23.3589 17.0196 23.3974 16.9128 23.3974 16.8024C23.3974 16.6921 23.3589 16.5853 23.2886 16.5002C23.2183 16.4152 23.1206 16.3573 23.0123 16.3366Z" fill={data?.AI_brand_color ? data?.AI_brand_color : "#6A47F2"}/>
                                        <path d="M22.4894 11.1554C22.3791 11.1554 22.2722 11.1169 22.1871 11.0466C22.102 10.9764 22.044 10.8787 22.0232 10.7703C21.5476 8.29107 21.2761 8.01955 18.7968 7.54398C18.6885 7.52314 18.5907 7.46519 18.5204 7.3801C18.4502 7.29501 18.4117 7.18809 18.4117 7.07773C18.4117 6.96737 18.4502 6.86045 18.5204 6.77536C18.5907 6.69027 18.6885 6.63232 18.7968 6.61147C21.2764 6.13555 21.5476 5.86399 22.0232 3.38515C22.044 3.27677 22.1019 3.17903 22.187 3.10874C22.2721 3.03845 22.379 3 22.4894 3C22.5998 3 22.7067 3.03845 22.7918 3.10874C22.8769 3.17903 22.9348 3.27677 22.9557 3.38515C23.4312 5.86399 23.7024 6.13555 26.182 6.61147C26.2904 6.63232 26.3881 6.69027 26.4584 6.77536C26.5287 6.86045 26.5671 6.96737 26.5671 7.07773C26.5671 7.18809 26.5287 7.29501 26.4584 7.3801C26.3881 7.46519 26.2904 7.52314 26.182 7.54398C23.7028 8.01951 23.4312 8.29107 22.9557 10.7703C22.9348 10.8787 22.8768 10.9764 22.7917 11.0466C22.7066 11.1169 22.5998 11.1554 22.4894 11.1554ZM20.7723 7.07773C21.1731 7.20914 21.5374 7.433 21.8357 7.73121C22.134 8.02943 22.3579 8.39364 22.4894 8.79441C22.6209 8.39364 22.8449 8.02943 23.1431 7.73121C23.4414 7.433 23.8057 7.20914 24.2065 7.07773C23.8058 6.94619 23.4416 6.72229 23.1433 6.42409C22.845 6.12589 22.621 5.76174 22.4894 5.36104C22.3578 5.76174 22.1338 6.12589 21.8355 6.42409C21.5373 6.72229 21.1731 6.9462 20.7723 7.07773Z" fill="#6A47F2"/>
                                    </svg>
                                    <p className="self-stretch text-gray-700 text-base font-medium leading-7 text-left">{question}</p>
                                </div>
                            </button>
                        ))}
                    </div>
                </div> :
                <Conversation setShowFormLast={setShowFormLast} showForm={showForm} setShowForm={setShowForm} setMessages={setMessages} messages={messages} showAvatar={data.avatar_logo_toggle} image={data?.avatar_logo_url} prompt={prompt} errorResponse={errorResponse} randomId={randomId} conversationId={conversationId} aiAnswer={aiAnswer} promptLoading={promptLoading} formData={formData} id={id} />
            }
            <div className='w-[786px] max-w-full px-5 sticky top-[100%] pb-2.5 lg:pt-6'>
                <form className={`relative mb-2.5 lg:mb-2`} onSubmit={(e) => {handlePrompt(e)}}>
                    <input disabled={promptLoading} value={inputValue} onChange={(e) => setInputValue(e.target.value)} type='text' id='prompt' name='prompt' placeholder={data?.AI_message_placeholder ? data.AI_message_placeholder : 'Enter a prompt here'} className={`md:h-[54px] h-[42px] focus-visible:outline-[#8451D4] focus:outline-[#8451D4] active:outline-[#8451D4] w-full px-4 md:py-3 py-[6px] pr-14 bg-[#f6f6f7] rounded-md border border-slate-300 text-[#1E293B] text-sm md:text-base font-normal leading-normal ${promptLoading && 'cursor-not-allowed'}`} />
                    <button disabled={promptLoading} type='submit' className={`w-[30px] h-[30px] absolute rounded-[5px] right-3 md:top-3 top-[5.5px] ${promptLoading && 'cursor-not-allowed'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                            <rect x="0.5" width="30" height="30" rx="5" fill={data?.AI_brand_color ? data?.AI_brand_color : "#6A47F2"}/>
                            <path d="M22.0472 14.4367L10.8685 8.81606C10.3064 8.56626 9.68189 9.06587 9.93169 9.62793L11.493 13.8121L18.6124 14.9987L11.493 16.1853L9.93169 20.3695C9.74434 20.9316 10.3064 21.4312 10.8685 21.1189L22.0472 15.4983C22.4844 15.311 22.4844 14.6865 22.0472 14.4367Z" fill="white"/>
                        </svg>
                    </button>
                </form>
                <div className='bottom-line text-center'>
                    <div className='text-gray-700 text-[10px] lg:text-xs font-normal leading-tight' dangerouslySetInnerHTML={{ __html: data?.AI_footer_message }} />
                    <div className="mt-1 flex xl:hidden mx-auto items-center gap-2 justify-center">
                        <div className="text-gray-600 text-[10px] lg:text-[13px] font-normal leading-[18px]">Powered by</div>
                        <BigLogo className='w-[70px]' />
                    </div>
                </div>
            </div>
            <div className="w-[198px] h-[30px] px-2.5 py-1.5 rounded justify-start items-center gap-2 xl:inline-flex absolute hidden bottom-5 right-5">
                <div className="text-gray-600 text-[13px] font-normal leading-[18px]">Powered by</div>
                <BigLogo />
            </div>
        </div>
    );
}

export default WidgetPage;
