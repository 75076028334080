import React, { useState, useEffect } from 'react';

const LocationDetector = () => {
  const [country, setCountry] = useState('Unknown');
  const [timezone, setTimezone] = useState('Unknown');

  useEffect(() => {
    const getLocationInfo = () => {
      try {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimezone(tz || 'Unknown');

        if (!tz) return { country: 'Unknown', timezone: 'Unknown' };

        const timezoneToCountry = {
            'Africa/Abidjan': 'CI',
          'Africa/Accra': 'GH',
          'Africa/Addis_Ababa': 'ET',
          'Africa/Algiers': 'DZ',
          'Africa/Cairo': 'EG',
          'Africa/Casablanca': 'MA',
          'Africa/Johannesburg': 'ZA',
          'Africa/Lagos': 'NG',
          'Africa/Nairobi': 'KE',
          'Africa/Tunis': 'TN',
          'America/Anchorage': 'US',
          'America/Argentina/Buenos_Aires': 'AR',
          'America/Bogota': 'CO',
          'America/Caracas': 'VE',
          'America/Chicago': 'US',
          'America/Denver': 'US',
          'America/Halifax': 'CA',
          'America/Havana': 'CU',
          'America/Lima': 'PE',
          'America/Los_Angeles': 'US',
          'America/Mexico_City': 'MX',
          'America/New_York': 'US',
          'America/Phoenix': 'US',
          'America/Santiago': 'CL',
          'America/Sao_Paulo': 'BR',
          'America/Toronto': 'CA',
          'America/Vancouver': 'CA',
          'Asia/Baghdad': 'IQ',
          'Asia/Bangkok': 'TH',
          'Asia/Beirut': 'LB',
          'Asia/Dhaka': 'BD',
          'Asia/Dubai': 'AE',
          'Asia/Hong_Kong': 'HK',
          'Asia/Istanbul': 'TR',
          'Asia/Jakarta': 'ID',
          'Asia/Jerusalem': 'IL',
          'Asia/Kabul': 'AF',
          'Asia/Karachi': 'PK',
          'Asia/Kathmandu': 'NP',
          'Asia/Kolkata': 'IN',
          'Asia/Calcutta': 'IN',
          'Asia/Kuala_Lumpur': 'MY',
          'Asia/Manila': 'PH',
          'Asia/Qatar': 'QA',
          'Asia/Riyadh': 'SA',
          'Asia/Seoul': 'KR',
          'Asia/Shanghai': 'CN',
          'Asia/Singapore': 'SG',
          'Asia/Taipei': 'TW',
          'Asia/Tehran': 'IR',
          'Asia/Tokyo': 'JP',
          'Australia/Adelaide': 'AU',
          'Australia/Brisbane': 'AU',
          'Australia/Melbourne': 'AU',
          'Australia/Perth': 'AU',
          'Australia/Sydney': 'AU',
          'Europe/Amsterdam': 'NL',
          'Europe/Athens': 'GR',
          'Europe/Belgrade': 'RS',
          'Europe/Berlin': 'DE',
          'Europe/Brussels': 'BE',
          'Europe/Bucharest': 'RO',
          'Europe/Budapest': 'HU',
          'Europe/Copenhagen': 'DK',
          'Europe/Dublin': 'IE',
          'Europe/Helsinki': 'FI',
          'Europe/Lisbon': 'PT',
          'Europe/London': 'GB',
          'Europe/Madrid': 'ES',
          'Europe/Moscow': 'RU',
          'Europe/Oslo': 'NO',
          'Europe/Paris': 'FR',
          'Europe/Prague': 'CZ',
          'Europe/Rome': 'IT',
          'Europe/Stockholm': 'SE',
          'Europe/Vienna': 'AT',
          'Europe/Warsaw': 'PL',
          'Europe/Zurich': 'CH',
          'Pacific/Auckland': 'NZ',
          'Pacific/Fiji': 'FJ',
          'Pacific/Guam': 'GU',
          'Pacific/Honolulu': 'US',
          'Pacific/Port_Moresby': 'PG',
          'Pacific/Sydney': 'AU'
          };
        const detectedCountry = timezoneToCountry[tz] || 'Unknown';
        setCountry(detectedCountry);

      } catch (error) {
        console.error('Error detecting location:', error);
        setCountry('Unknown');
        setTimezone('Unknown');
      }
    };

    getLocationInfo();
  }, []);

  return (country);
};

export default LocationDetector;