import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WidgetPage from './WidgetPage';

function App() {
  return (
    <Router>
      <Routes>
        {/* Define a route with an id parameter */}
        <Route path="/" element={<WidgetPage />} />
      </Routes>
    </Router>
  );
}

export default App;